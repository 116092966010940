import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import WithLayout from '../WithLayout';
import { isEmail } from '../../domain/utils/stringUtils';
import { newsletterApiService } from '../../domain/services/newsletterApiService';
import { t } from '../../domain/services/configService';
import {
  consentMgmt,
  consentMgmtCheckbox,
  consentMgmtContents,
  consentMgmtEmailBox,
  consentMgmtErrorMessage,
  consentMgmtImageOne,
  consentMgmtImageTwo,
  consentMgmtLoadingImage,
  consentMgmtPhotoCredit,
  consentMgmtSuccessText
} from '../../components/Styles/consentManagement';

const Newsletter = () => {
  const [state, setState] = useState({
    success: false,
    consent: null,
    isLoading: true,
    error: false
  });
  const networkThreeError = useRef();
  const ageVerificationError = useRef();
  const emailInput = useRef();
  const emailError = useRef();
  const checkboxError = useRef();
  const errorMsg = {
    400: useRef(),
    409: useRef(),
    428: useRef(),
    429: useRef()
  };

  useEffect(() => {
    newsletterApiService
      .getConsentMetadata()
      .then(response => {
        if (response.status !== 200) {
          // eslint-disable-next-line no-throw-literal
          throw 'error';
        }
        return response.json();
      })
      .then(data => {
        setState({ ...state, isLoading: false, consent: data });
      })
      .catch(() => {
        setState({ ...state, isLoading: false, error: true });
        if (networkThreeError.current) {
          networkThreeError.current.style.display = 'block';
        }
      });
  }, []);

  const submit = e => {
    e.preventDefault();

    let errors = false;
    if (typeof document === 'object') {
      const checkboxes = document.querySelectorAll(
        '.consent-mgmt__checkbox-input'
      );
      const textInputs = document.querySelectorAll('.consent-mgmt__text-input');

      checkboxes.forEach(checkbox => {
        if (checkbox.required && !checkbox.checked) {
          checkbox.parentElement.parentElement
            .querySelector('h7')
            .classList.add('error');
          errors = true;
        } else {
          checkbox.parentElement.parentElement
            .querySelector('h7')
            .classList.remove('error');
        }
        if (checkbox.id === 'age_verification') {
          // eslint-disable-next-line no-undef
          const ageChallenge = FNGUserAPI.ageVerification(checkbox.checked);
          if (!checkbox.checked) {
            checkbox.parentElement.parentElement
              .querySelector('h7')
              .classList.add('error');
            errors = true;
          } else if (ageChallenge === false) {
            const inputLabel =
              checkbox.parentElement.parentElement.querySelector('h7');
            inputLabel.classList.add('error');
            ageVerificationError.current.style.display = 'block';
            errors = true;
          }
        }
      });
      textInputs.forEach((textInput, i) => {
        if (textInput.required && textInput.value === '') {
          checkboxes[i].classList.add('error');
          errors = true;
        } else {
          checkboxes[i].classList.remove('error');
        }
      });
      if (!errors && isEmail(emailInput.current.value)) {
        setState({ ...state, isLoading: true });
        emailError.current.style.display = 'none';
        checkboxError.current.style.display = 'none';
        networkThreeError.current.style.display = 'none';
        const form = document.querySelector('.news-modal .consent-mgmt__form');
        const bodyData = {};
        [...form.elements].forEach(element => {
          if (element.name !== '') {
            if (element.type === 'checkbox') {
              bodyData[element.name] = element.checked;
            } else {
              bodyData[element.name] = element.value;
            }
          }
        });
        newsletterApiService
          .subscribe(bodyData)
          .then(response => {
            if (response.status !== 200 && response.status !== 202) {
              // eslint-disable-next-line no-throw-literal
              throw `error${response.status}`;
            }
            setState({ ...state, isLoading: false, success: true });
          })
          .catch(error => {
            setState({ ...state, isLoading: false });
            [...form.elements].forEach((element, i) => {
              if (element.name !== '') {
                if (element.type === 'checkbox') {
                  form.elements[i].checked = bodyData[element.name];
                } else {
                  form.elements[i].value = bodyData[element.name];
                }
              }
            });
            errorMsg[error].current.style.display = 'block';
          });
      } else {
        if (errors) {
          if (ageVerificationError.current.style.display === 'block') {
            checkboxError.current.style.display = 'none';
          } else {
            checkboxError.current.style.display = 'block';
          }
        } else {
          checkboxError.current.style.display = 'none';
        }

        if (!isEmail(emailInput.current.value)) {
          emailError.current.style.display = 'block';
        } else {
          emailError.current.style.display = 'none';
        }
      }
    }
  };

  return (
    <>
      <Helmet>
        <script
          src="https://specials.fnghub.com/ng/global/js/age-verification.js"
          async
        />
      </Helmet>
      <div
        id="news-modal"
        className="consent-mgmt news-modal scrollable"
        css={consentMgmt}
      >
        {!state.success ? (
          <div>
            <div css={consentMgmtImageOne}>
              <img alt="" src="/images/newsletter__image-1.jpg" />
            </div>

            <div css={consentMgmtImageTwo}>
              <img alt="" src="/images/newsletter__image-2.jpg" />
            </div>

            <div css={consentMgmtContents}>
              <h5>{t('register')}</h5>

              <h3>{t('receive our weekly newsletter')}</h3>

              <p>
                {t(
                  'Leave us your email address and periodically receive all the news from National Geographic'
                )}
              </p>
              {state.isLoading && (
                <img
                  css={consentMgmtLoadingImage}
                  src="/images/ng-logo-animated.gif"
                  alt="Loading"
                />
              )}
              {!state.isLoading && !state.error && (
                <form
                  className="consent-mgmt__form"
                  onSubmit={submit}
                  noValidate
                >
                  <input
                    ref={emailInput}
                    name="email"
                    aria-label="Email"
                    css={consentMgmtEmailBox}
                    placeholder={t('Enter your email')}
                  />
                  <button type="submit">{t('register')}</button>

                  {state.consent &&
                    Object.keys(state.consent.consent.configuration).map(
                      (key, index) => {
                        const consent =
                          state.consent.consent.configuration[key];
                        const inputRequired = consent.required ? ' *' : '';
                        if (consent.type === 'checkbox') {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`consent-mgmt__${index}`}>
                              <div
                                className="consent-mgmt__checkbox"
                                css={consentMgmtCheckbox}
                              >
                                <input
                                  type="checkbox"
                                  required={consent.required}
                                  className="consent-mgmt__checkbox-input"
                                  data-index={index}
                                  id={key}
                                  name={key}
                                  value="1"
                                />
                              </div>
                              <h7
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: consent.text + inputRequired
                                }}
                              />
                            </div>
                          );
                        }

                        if (consent.type === 'text') {
                          return (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={`consent-mgmt__${index}`}
                              className="consent-mgmt__input-text"
                            >
                              <input
                                className="consent-mgmt__text-input"
                                required={consent.required}
                                type="text"
                                data-index={index}
                                id={key}
                                name={key}
                                placeholder={consent.text + inputRequired}
                              />
                            </div>
                          );
                        }

                        if (consent.type === 'info') {
                          return (
                            <div
                              // eslint-disable-next-line react/no-array-index-key
                              key={`consent-mgmt__${index}`}
                              className="consent-mgmt__input-text"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: consent.text
                              }}
                            />
                          );
                        }

                        if (consent.type === 'hidden') {
                          return (
                            <input
                              // eslint-disable-next-line react/no-array-index-key
                              key={`consent-mgmt__${index}`}
                              type="hidden"
                              data-index={index}
                              id={key}
                              name={key}
                              value={consent.text}
                            />
                          );
                        }

                        return null;
                      }
                    )}
                </form>
              )}
              <div
                className="consent-mgmt__error-message"
                css={consentMgmtErrorMessage}
              >
                <span ref={ageVerificationError}>
                  {t("Sorry, you aren't eligible for this offer")}
                </span>
                <span ref={emailError}>
                  {t('please enter a valid email address')}
                </span>
                <span ref={checkboxError}>
                  {t('please fill all required fields')}
                </span>
                <span ref={networkThreeError}>
                  {t('Network error. Please try again later.')}
                </span>
                <span ref={errorMsg[400]}>
                  {t('Network error. Please try again later.')}
                </span>
                <span ref={errorMsg[409]}>{t('User already subscribed.')}</span>
                <span ref={errorMsg[428]}>
                  {t(
                    'User already requested to subscribe, waiting for confirmation.'
                  )}
                </span>
                <span ref={errorMsg[429]}>
                  {t(
                    'User already requested to subscribe within the last 5 minutes.'
                  )}
                </span>
              </div>
            </div>

            <div css={consentMgmtPhotoCredit}>
              {`${t('photo credit')} `}
              {t('Joel Sartore, Photo Ark')}
            </div>
          </div>
        ) : (
          <div className="success">
            <div css={consentMgmtImageOne}>
              <img alt="" src="/images/newsletter__image-1.jpg" />
            </div>

            <div css={consentMgmtImageTwo}>
              <img alt="" src="/images/newsletter__image-2.jpg" />
            </div>

            <div css={consentMgmtContents}>
              <h5>{t('register')}</h5>

              <h3>{t('thanks')}</h3>

              <h6>
                {`${t('thanks for subscribing to our newsletter')}. ${t(
                  'we have sent you an email to confirm your subscription'
                )}`}
              </h6>

              <p css={consentMgmtSuccessText}>
                {t('you have successfully subscribed')}
              </p>
            </div>

            <div css={consentMgmtPhotoCredit}>
              {`${t('photo credit')}: `}
              {t('Joel Sartore, Photo Ark')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WithLayout(Newsletter);
